import React, { useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import DayPickerInput from "react-day-picker/DayPickerInput";

// TODO remove moment from this package, use luxon instead (or nothing)!  #FE-91, #SEC-92
import moment from "moment";
import { useIntl } from "react-intl";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "moment/dist/locale/en-gb";
import { Tooltip } from "@happeouikit/tooltip";
import { BodyUI, sansFamily } from "@happeouikit/typography";
import {
  gray06,
  black,
  active,
  gray01,
  toBgLight,
  lighten,
  white,
  gray03,
  gray05,
} from "@happeouikit/colors";
import { margin200, padding300 } from "@happeouikit/layout";
import { IconButton, ButtonSecondary } from "@happeouikit/buttons";
import {
  IconChevronLeft,
  IconChevronRight,
  IconCalendar,
  IconCheck,
} from "@happeouikit/icons";
import { radius500, shadow500 } from "@happeouikit/theme";

import { components } from "react-select";
import Dropdown from "./Dropdown";

// CSS
import "react-day-picker/lib/style.css";
import messages from "./messages";
import { useDevWarningOnce, uuidv4 } from "./utils";

const formatValue = (value, locale) => {
  if (!value) return null;

  return moment(value)
    .locale(locale)
    .format("L");
};

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconCalendar
        style={{
          position: "absolute",
          paddingLeft: "10px",
          fill: gray03,
          height: "20px",
          width: "20px",
          boxSizing: "content-box",
        }}
      />
      <input data-testid="datepicker-input" ref={ref} {...props} />
    </div>
  );
});

const DatePicker = ({
  label,
  locale,
  width,
  date,
  type,
  fromMonth,
  disabledDays,
  value,
  pickerRef,
  inputProps = {},
  dayPickerProps = {},
  ...props
}) => {
  // Stores the ID of the input field. If "id" isn't provided, it will be generated
  // to improve accessibility by linking label to the input field.
  const { current: datePickerId } = useRef(inputProps.id || uuidv4());
  const [month, setMonth] = useState(fromMonth);
  const { from, to } = date;
  const localeFormat = moment.localeData(locale).longDateFormat("L");
  const formattedValue = formatValue(value || date[type], locale);
  const isRangeMode = Boolean(from) && Boolean(to);
  const intl = useIntl();

  useDevWarningOnce(
    value && date[type],
    "[DatePicker] Both the `value` and `date` props have been used.\n  The `date` prop will be ignored. To get rid of this warning, remove one of these props."
  );

  const onChangeMonthYear = ({ month, year }) => {
    setMonth(new Date(year, month));
  };

  const pickerDisabledDays = useMemo(() => {
    if (isRangeMode) {
      if (type === "from") {
        return {
          before: disabledDays.before,
          after: to || disabledDays.after,
        };
      }
      return {
        before: from || disabledDays.before,
        after: disabledDays.after,
      };
    }
    return disabledDays;
  }, [type, isRangeMode, disabledDays, from, to]);

  return (
    <Wrapper width={width} hasRange={isRangeMode}>
      {label && (
        <label
          htmlFor={datePickerId}
          aria-label={label}
          style={{ margin: "4px 0" }}
        >
          <BodyUI bold as="span" color={gray01}>
            {label}
          </BodyUI>
        </label>
      )}
      <DayPickerInput
        component={CustomInput}
        value={formattedValue}
        key={date[type]}
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder={localeFormat}
        dayPickerProps={{
          locale,
          localeUtils: MomentLocaleUtils,
          className: "Range",
          disabledDays: pickerDisabledDays,
          modifiers: { before: from, after: to },
          selectedDays: isRangeMode ? { from, to } : undefined,
          navbarElement: (
            <CustomNavbar
              onChangeMonthYear={onChangeMonthYear}
              disabledDays={pickerDisabledDays}
            />
          ),
          showOutsideDays: true,
          month,
          ...dayPickerProps,
        }}
        ref={pickerRef}
        inputProps={{
          id: datePickerId,
          "aria-label": label,
          /**
           * If your console gives you a log error like this:
           *     Warning: Invalid aria prop `aria-description` on <input> tag.
           * You can safely ignore it.
           * It will be fixed when we upgrade to React 18.
           * See https://github.com/facebook/react/pull/22142
           */
          "aria-description": intl.formatMessage(
            messages.selectDateFormatDescription,
            {
              localeFormat,
            }
          ),
          ...inputProps,
        }}
        {...props}
      />
    </Wrapper>
  );
};

DatePicker.defaultProps = {
  type: "to",
  fromMonth: new Date() || "",
  date: { from: "", to: "" },
  locale: "en-gb",
  label: "",
  width: undefined,
  value: undefined,
  pickerRef: undefined,
  disabledDays: [],
};

DatePicker.propTypes = {
  fromMonth: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  locale: PropTypes.string,
  width: PropTypes.string,
  /** Type is used to pick the date[type] value to display. Useful when range with 2 inputs (from & to). */
  type: PropTypes.oneOf(["from", "to"]),
  /** Use either value or date.to properties. If you use value it will overwrite the date.to if it is empty string. */
  value: PropTypes.instanceOf(Date),
  /** Format: { from: Date|number|string, to: Date|number|string } */
  date: (props, propName) => {
    const { from, to } = props[propName];

    if (typeof from === "undefined" || typeof to === "undefined") {
      return new Error(
        "Date prop required. Format: { from: Date|number|string, to: Date|number|string }. "
      );
    }

    return null;
  },
  /** Can be used to disable range of dates. Also restricts the year selector. Example: { after: new Date(2025, 0, 0), before: new Date(2020, 0, 0) }  */
  disabledDays: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  /** Can be used to tap a ref to the picker. See Range select code example how to utilise. */
  pickerRef: PropTypes.oneOfType([
    PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

const CustomNavbar = ({
  month,
  previousMonth,
  nextMonth,
  onPreviousClick,
  onNextClick,
  onChangeMonthYear,
  disabledDays,
}) => {
  const intl = useIntl();
  const [showYearMonthPicker, setShowYearMonthPicker] = useState(false);

  // using toDateString due to previousMonth and nextMonth are at 12:00
  const enabledPreviousArrow = useMemo(
    () =>
      !disabledDays?.before ||
      new Date(previousMonth.toDateString()) >=
        moment(disabledDays.before).startOf("month"),
    [previousMonth, disabledDays]
  );

  const enabledNextArrow = useMemo(
    () =>
      !disabledDays?.after ||
      new Date(nextMonth.toDateString()) <=
        moment(disabledDays.after).startOf("month"),
    [nextMonth, disabledDays]
  );

  const disabledStyle = { fill: gray06, cursor: "default" };

  return (
    <>
      <NavBarContainer>
        <IconButton
          icon={IconChevronLeft}
          isActionIcon
          onClick={() => enabledPreviousArrow && onPreviousClick()}
          aria-label={intl.formatMessage(messages.pickerPrevMonth)}
          style={(!enabledPreviousArrow && disabledStyle) || {}}
          data-testid="datepicker-select-prev-month"
        />
        <ButtonSecondary
          onClick={() => setShowYearMonthPicker(true)}
          aria-label={intl.formatMessage(messages.pickerSelectYear)}
          data-tooltip-content={intl.formatMessage(messages.pickerSelectYear)}
          data-tooltip-id="tooltip-datepicker-select-year"
          data-testid="datepicker-select-year"
          text={moment(month).format("MMMM YYYY")}
        />
        <Tooltip id="tooltip-datepicker-select-year" />
        <IconButton
          icon={IconChevronRight}
          isActionIcon
          onClick={() => enabledNextArrow && onNextClick()}
          aria-label={intl.formatMessage(messages.pickerNextMonth)}
          style={(!enabledNextArrow && disabledStyle) || {}}
          data-testid="datepicker-select-next-month"
        />

      </NavBarContainer>
      {showYearMonthPicker && (
        <MonthPicker
          date={month}
          disabledDays={disabledDays}
          onChange={(value) => {
            setShowYearMonthPicker(false);
            onChangeMonthYear(value);
          }}
        />
      )}
    </>
  );
};

const MonthPicker = ({ date, disabledDays, onChange }) => {
  const intl = useIntl();
  const [selectedMonth, setSelectedMonth] = useState(new Date(date).getMonth());
  const [selectedYear, setSelectedYear] = useState(
    new Date(date).getFullYear()
  );
  const { after, before } = disabledDays || {};

  const getYears = () => {
    const currentYear = new Date().getFullYear();
    const { after, before } = disabledDays || {};
    const fromYear = before ? new Date(before).getFullYear() : 1939;
    const toYear = after
      ? new Date(after).getFullYear()
      : new Date(currentYear + 10, 11).getFullYear();
    const years = [];

    // eslint-disable-next-line no-plusplus
    for (let i = toYear; i >= fromYear; i--) {
      years.push({ label: i, value: i });
    }

    return years;
  };

  const isDisabledMonth = (month) => {
    const monthDate = moment()
      .set({ year: selectedYear, month })
      .startOf("month");
    return (
      (before && monthDate < moment(before).startOf("month")) ||
      (after && monthDate > moment(after).startOf("month"))
    );
  };

  const months = moment.monthsShort();
  const DropdownYearOption = ({ children, ...props }) =>
    components.Option && (
      <div
        onMouseDown={(e) => e.stopPropagation()}
        role="option"
        aria-selected
        tabIndex="0"
      >
        <components.Option {...props}>{children}</components.Option>
      </div>
    );

  return (
    <YearMonthSelectContainer>
      <YearSelectContainer data-testid="yearmonth-dropdown-select">
        <Dropdown
          aria-label={intl.formatMessage(messages.selectYearDropdown)}
          options={getYears()}
          components={{ Option: DropdownYearOption }}
          value={{
            label: selectedYear,
            value: selectedYear,
          }}
          onChange={(val) => {
            setSelectedYear(val.value);
          }}
        />
      </YearSelectContainer>
      <MonthUl>
        {months.map((month, i) => (
          <li key={month} value={i}>
            <MonthSelectButton2
              data-testid={`select-month-${month}`}
              $isActive={i === selectedMonth}
              disabled={isDisabledMonth(month)}
              onClick={() => {
                if (!isDisabledMonth(month)) setSelectedMonth(i);
              }}
              aria-label={month}
              text={month}
            />
          </li>
        ))}
      </MonthUl>
      <ActionList>
        <IconButton
          aria-label={intl.formatMessage(messages.closeYearMonthPicker)}
          icon={IconCheck}
          onClick={() => onChange({ month: selectedMonth, year: selectedYear })}
          data-tooltip-content={intl.formatMessage(messages.closeYearMonthPicker)}
          data-tooltip-id="datepicker-year-month-done"
          data-testid="datepicker-year-month-done"
        />
        <Tooltip id="datepicker-year-month-done" />
      </ActionList>
    </YearMonthSelectContainer>
  );
};

const YearMonthSelectContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${white};
  padding: ${padding300};
`;

const ActionList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${padding300};
`;

const YearSelectContainer = styled.div`
  align-items: center;
  justify-content: center;
  margin-bottom: ${margin200};
  > div {
    width: 100%;
  }
`;

const MonthUl = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
  width: 100%;
  margin: ${padding300} 0 0 0;
  padding: 0;
  list-style: none;
  li {
    width: 100%;
  }
`;

const MonthSelectButton2 = styled(ButtonSecondary)`
  padding: 8px;

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${lighten(active, 0.9)};
      p {
        color: ${active};
        font-weight: bold;
      }
    `}
`;

const borderRadius = "100px";
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || "200px"};

  .DayPickerInput-Overlay {
    margin-top: 8px;
    box-shadow: ${shadow500};
    border-radius: ${radius500};
  }

  .DayPickerInput {
    .Range {
      .DayPicker-Day {
        min-width: 21px;
        :focus {
          outline: none;
        }
        &.DayPicker-Day--selected:not(.DayPicker-Day--before):not(.DayPicker-Day--after) {
          ${({ hasRange }) =>
            hasRange &&
            css`
              background-color: ${toBgLight(active)};
              border-radius: 0;
              &:not(.DayPicker-Day--outside) {
                color: ${gray01};
              }
              &.DayPicker-Day--outside {
                // Original gray was too bright and looked bad on selected background
                color: ${gray05};
              }
            `}
        }
        &.DayPicker-Day--selected {
          background-color: ${active};
          color: ${white};
        }

        &.DayPicker-Day--before {
          position: relative;

          ${({ hasRange }) =>
            hasRange &&
            css`
              ::before {
                content: "";
                position: absolute;
                background-color: ${toBgLight(active)};
                width: 10px;
                height: 35px;
                top: 0;
                right: 0;
                z-index: -1;
              }
            `}
        }
        &.DayPicker-Day--after {
          position: relative;
          ${({ hasRange }) =>
            hasRange &&
            css`
              ::before {
                content: "";
                position: absolute;
                background-color: ${toBgLight(active)};
                width: 10px;
                height: 35px;
                top: 0;
                left: 0;
                z-index: -1;
              }
            `}
        }
        &.DayPicker-Day--today:not(.DayPicker-Day--selected) {
          color: ${active};
        }

        &.DayPicker-Day--outside {
          &.DayPicker-Day--before,
          &.DayPicker-Day--after {
            color: ${white};
          }
        }
      }
    }
    input {
      font-family: ${sansFamily};
      width: 100%;
      border-radius: 4px;
      border: solid 1px ${gray06};
      box-sizing: border-box;
      box-shadow: none;
      font-size: 14px;
      padding: 9px 12px 9px 38px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: 0.3px;
      color: ${black};

      :hover {
        border-color: ${gray06};
      }

      :active {
        outline: none;
        box-shadow: none;
        border-color: ${active};
      }

      :focus {
        outline: none;
        border-color: ${active};
      }
    }

    .DayPicker-wrapper {
      font-family: ${sansFamily};
      color: ${gray01};
      letter-spacing: -0.3px;
      font-size: 14px;
      font-weight: normal;
    }

    .DayPicker-Week {
      ${({ hasRange }) =>
        hasRange &&
        css`
          .DayPicker-Day--selected:not(.DayPicker-Day--before):not(.DayPicker-Day--after) {
            &:first-child {
              border-top-left-radius: ${borderRadius} !important;
              border-bottom-left-radius: ${borderRadius} !important;
            }
            &:last-child {
              border-top-right-radius: ${borderRadius} !important;
              border-bottom-right-radius: ${borderRadius} !important;
            }
          }
        `}

      .DayPicker-Day--selected {
        &:first-child {
          &.DayPicker-Day--after {
            ::before {
              content: none;
            }
          }
        }
        &:last-child {
          &.DayPicker-Day--before {
            ::before {
              content: none;
            }
          }
        }
      }
    }

    .DayPicker-Caption {
      display: none;
    }
  }
`;

const NavBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 14px 0px 14px;
`;
export default DatePicker;
