import { forwardRef, useRef, useImperativeHandle, Ref } from "react";
import styled, { css } from "styled-components";

import Select, {
  Props as SelectProps,
  DropdownIndicatorProps,
  components,
} from "react-select";
import { IconArrowDropDown } from "@happeouikit/icons";

import { BodyUI, sansFamily } from "@happeouikit/typography";
import { screen } from "@happeouikit/theme";

const DropdownIndicator = (props: DropdownIndicatorProps) =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <IconArrowDropDown
        width="24px"
        height="24px"
        fill="var(--color-placeholder-text-on-light)"
      />
    </components.DropdownIndicator>
  );

interface DropdownProps extends SelectProps {
  label: string;
  hideOnMobile?: boolean;
}

interface DropdownRef {
  setValue: (value: any) => void;
}

const Dropdown = (
  { label, ...props }: DropdownProps,
  ref: Ref<DropdownRef>
) => {
  const dropdownRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    setValue: (value) => dropdownRef.current?.select.setValue(value),
  }));

  return (
    <Container isDisabled={props.isDisabled}>
      {!!label && (
        <Label>
          <BodyUI bold color="var(--color-secondary-text-on-light, #4a5464)">
            {label}
          </BodyUI>
        </Label>
      )}
      <StyledDropdown
        classNamePrefix="dropdown"
        components={{ DropdownIndicator, ...props.components }}
        hideOnMobile={props.hideOnMobile}
        ref={dropdownRef as any}
        {...props}
      />
    </Container>
  );
};

export const dropdownStyles = css<{ hideOnMobile?: boolean }>`
  min-width: 200px;
  width: auto;

  font-family: ${sansFamily};
  letter-spacing: -0.3px;
  font-size: 14px;
  font-weight: normal;

  :hover {
    cursor: pointer;
  }

  .dropdown {
    &__indicator-separator {
      display: none;
    }
    &__indicator {
      padding: 7px 8px;
    }
    &__control {
      border: 1px solid var(--color-stroke, #95a0b1);
      box-shadow: none;
      background-color: var(--color-surface, #ffffff);

      :disabled {
        border: solid 1px var(--color-stroke-lighten);
        color: var(--color-gray500, #68778d);

        ::placeholder {
          color: var(--color-gray500, #68778d);
        }
      }

      :hover {
      }

      :active {
        outline: none;
        border-color: var(--color-focus-indicator, #1691fe);
      }

      :focus {
        outline: none;
        border-color: var(--color-focus-indicator, #1691fe);
      }

      &--is-focused,
      &--is-focused:active {
        outline: none !important;
        border: 1px solid var(--color-focus-indicator, #1691fe) !important;
        box-shadow: none;
      }
    }

    &__input {
      input {
        box-shadow: none !important;
      }
    }

    &__menu {
      background-color: var(--color-surface, #ffffff);
      color: var(--color-secondary-text-on-light, #4a5464);
      .dropdown__option--is-focused:not(.dropdown__option--is-selected) {
        background: var(--color-active-lighten50, #80baed);
      }
    }

    &__placeholder {
      font-family: ${sansFamily};
      color: var(--color-placeholder-text-on-light, #95a0b1);
      letter-spacing: -0.3px;
      font-size: 14px;
      font-weight: normal;
    }

    &__single__input-container,
    &__single-value {
      color: var(--color-secondary-text-on-light, #4a5464);
    }
  }

  @media ${screen.onlyXs} {
    ${({ hideOnMobile }) => hideOnMobile && " display: none;"};
  }
`;

const StyledDropdown = styled(Select)`
  ${dropdownStyles}
`;

const Label = styled.div<{ isDisabled?: boolean }>`
  margin-bottom: var(--space-sm, 8px);
  ${({ isDisabled }) =>
    isDisabled &&
    `
     opacity: 0.4;
  `}
`;

const Container = styled.div<{ isDisabled?: boolean }>`
  ${({ isDisabled }) =>
    isDisabled &&
    ` ${Label},
      ${StyledDropdown} {
        opacity: 0.4;
      }
    `}
`;

export default forwardRef(Dropdown);
